<template>
  <div class="container mt-5 mb-5">
    <CostsList />
  </div>
</template>

<script>
import CostsList from "@/components/lists/CostsList.vue";

export default {
  components: {
    CostsList,
  },
};
</script>