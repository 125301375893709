// apiConfig.js
export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL + "api/";
export const IMAGES_PATH = process.env.VUE_APP_BACKEND_URL + "storage/";
export const API_SANCTUM_URL = process.env.VUE_APP_SANCTUM_URL;
export const CHECK_TOKEN_URL = "check-token";
export const ACCOUNT_URL = "accounts";
export const COMPANY_URL = "companies";
export const COMPANY_URL_PARAMENTER = "companies/";
export const JOURNEY_URL = "journeys";
export const JOURNEY_URL_PARAMETER = "journeys/";
export const JOURNEY_URL_QUERY = "journeys?";
export const JOURNEY_CHECK_OPEN = "journeys/check-open-journey";
export const JOURNEY_BY_TASK_URL_QUERY = "journeys-by-task-id?";
export const LEAD_URL = "leads";
export const LOGIN_URL = "login";
export const LOGOUT_URL = "logout";
export const OPPORTUNITY_URL = "opportunities";
export const OPPORTUNITY_COUNT = "opportunities/totalOpportunities";
export const PROJECT_URL = "projects";
export const PROJECT_URL_PARAMETER = "projects/";
export const PROJECTS_PRIORIZED_URL = "projects/prioritized";
// export const PROJECT_STATUS_URL = "projects/";
export const PROPOSAL_COUNT = "proposals/totalProposals";
export const PROPOSALS_BY_OPPORTUNITY_URL = "proposals/proposals-by-opportunity-id?";
export const SERVICE_URL = "services";
export const TASK_URL = "tasks";
export const TASK_URL_PARAMETER = "tasks/";
export const TASK_STATUS_URL = "tasks/status";
export const TASK_PRIORIZED_URL = "tasks/prioritized";
export const TASK_BY_PROJECT_URL = "tasks-by-project-id?";
export const TASK_BY_OPPORTUNITY_URL = "tasks-by-opportunity-id?";
export const USER_URL = "users";
export const USER_CURRENT_URL = "user";
