<template>
    <ServicesList />
</template>

<script>
import ServicesList from "@/components/lists/ServicesList.vue";

export default {
  name: "servicesIndex",
  components: {
    ServicesList,
  },
};
</script>