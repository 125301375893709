<template>
  <OpportunitiesList template="index" />
</template>

<script>
import OpportunitiesList from "@/components/lists/OpportunitiesList.vue";

export default {
  components: {
    OpportunitiesList,

  },
};

</script>
